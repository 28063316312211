import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";

import { renderSimpleLabel } from "./shared/Utilities";

export default function ReviewAndSubmit() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [fuelProgramApplication, setFuelProgramApplication] = useState();

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/review-and-submit");

    setFuelProgramApplication(response.result?.fuel_program_application)

    setBusy(false);
  }

  const submit = async () => {
    setSaveBusy(true);

    const response = await client.postData("/user-portal/fpa/review-and-submit", {});
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  const last_four = (val) => {
    if (val) {
      return val.substr(val.length-5);
    }
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Review and Submit Application</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />
          
          <div className="row">
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.first_name} {fuelProgramApplication.last_name} 
                {renderSimpleLabel('Name')}
              </div>
            </div>
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.email} 
                {renderSimpleLabel('Email')}
              </div>
            </div>
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.phone} 
                {renderSimpleLabel('Phone')}
              </div>
            </div>
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.referred_by} 
                {renderSimpleLabel('Referred By')}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.bank_name}<br/>
                Rt #{last_four(fuelProgramApplication.bank_routing_number)} / Acct #{last_four(fuelProgramApplication.bank_account_number)}  
                {renderSimpleLabel('Verified Bank Info')}
              </div>
            </div>
            <div className="col-md-3">
              <div className="md-form text-only">
                {fuelProgramApplication.signed_at}<br/>
                {fuelProgramApplication.signature_hash ? fuelProgramApplication.signature_hash.replace("SIGHASH-", "Sig#") : null}
                {renderSimpleLabel('Signature Date')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form text-only">
                {fuelProgramApplication.expedited_shipping === 'Y' && (
                <div>
                  Fastest: $70 one-time charge<br/>
                  - 48-hour processing<br/>
                  - Overnight shipping
                </div>
                )}
                {fuelProgramApplication.expedited_processing === 'Y' && (
                <div>
                  Fast: $20 one-time charge<br/>
                  - 48-hour processing<br/>
                </div>
                )}
                {fuelProgramApplication.expedited_shipping !== 'Y' && fuelProgramApplication.expedited_processing !== 'Y' && (
                <div>
                  No
                </div>
                )}
                {renderSimpleLabel('Expedited Delivery')}
              </div>
            </div>
          </div>

          <button onClick={submit} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
            Submit Application
            <div className="Ripple"></div>
          </button>
        </>
      )}
      </div>
    </>
  </div>
  );
}
