import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { accountTypes } from "./shared/AccountTypes";

export default function ThankYou() {
  const [busy, setBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const returnToDashboard = async () => {
    window.location.href = '/user-portal/dashboard';
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Thank You</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />
          
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h1>All Done!</h1>
              <img src="/assets/balloons.gif" height="120px" />
              <h3>Your application was submitted successfully</h3>
              <p>We will start processing your application soon.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <button onClick={returnToDashboard} type="button" className="btn Ripple-parent btn-success">
                Return to Dashboard
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
