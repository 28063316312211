import React, { useState } from "react";

export default function SsnInput({value, onChange, id}) {
  const [showHidden, setShowHidden] = useState(false);
  
  const handleSetSsn = (ssn) => {
    onChange(ssn);
  }

  const handleToggleShowHidden = () => {
    setShowHidden(!showHidden);
  }

  return (
  <>
    <input id={id} type={showHidden ? 'text' : 'password'} value={value} onChange={(e) => handleSetSsn(e.target.value)} className="form-control" />
    <span onClick={handleToggleShowHidden} toggle="#ssn-field" class={`fa fa-fw ${showHidden ? 'fa-eye' : 'fa-eye-slash'} field-icon toggle-hidden`}></span>
  </>
  );
}
