import React, { useEffect, useState, useCallback } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { renderSimpleLabel } from "./shared/Utilities";
import { accountTypeDisplay } from "./shared/Utilities";
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link';

// https://github.com/plaid/react-plaid-link
// https://github.com/plaid/react-plaid-link/blob/master/examples/simple.tsx

export const GOOD_RESULT = 'Success';
export const BAD_RESULT = 'Bad';

export const VERIFICATION_STATUS_UNVERIFIED = 'Unverified'
export const VERIFICATION_STATUS_PENDING_DEPOSIT = 'Pending Deposit Verification'
export const VERIFICATION_STATUS_VERIFIED = 'Verified'
export const VERIFICATION_STATUS_REJECTED = 'Rejected'

export const VERIFICATION_METHOD_ACH_DEPOSIT = "ACH Deposit"
export const VERIFICATION_METHOD_PLAID_INSTANT = "Plaid (Instant)"
export const VERIFICATION_METHOD_MANUAL = "Manual"

export default function BankVerification() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [achVerificationErrorMessages, setAchVerificationErrorMessages] = useState([]);
  
  const [linkToken, setLinkToken] = useState(null);
  const [publicToken, setPublicToken] = useState(null);

  const [achDeposit1, setAchDeposit1] = useState(null);
  const [achDeposit2, setAchDeposit2] = useState(null);

  const [bankName, setBankName] = useState(null);
  const [accountType, setAccountType] = useState(null);

  const [verificationMethod, setVerificationMethod] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [verifiedAt, setVerifiedAt] = useState(null);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/bank-verification");

    setBankName(response.result?.bank_account?.bank_name);
    setAccountType(response.result?.bank_account.account_type || 'C');

    setVerificationMethod(response.result?.bank_account.verification_method);
    setVerificationStatus(response.result?.bank_account.verification_status);
    setVerifiedAt(response.result?.bank_account.verified_at);

    setBusy(false);
  }
  
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      setPublicToken(publicToken);
    },
    onEvent: null,
    onExit: () => {
      // Do nothing
    }
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  useEffect(async () => {
    if (publicToken) {
      setSaveBusy(true);

      const request = {
        bank_account: {
          plaid_token: publicToken
        }
      };
      const response = await client.postData('/user-portal/fpa/verify-bank-account-on-file', request);
  
      if (response.result?.user_bank_account_valid) {
        setVerificationStatus(VERIFICATION_STATUS_VERIFIED);
      } else {
        setVerificationStatus(VERIFICATION_STATUS_REJECTED);
      }

      setSaveBusy(false);
    }
  }, [publicToken]);

  const handleStartInstantVerification = async () => {
    setSaveBusy(true);
    const response = await client.postData("/user-portal/fpa/create-link-token");
    setVerificationMethod(VERIFICATION_METHOD_PLAID_INSTANT);
    setLinkToken(response.result?.link_token);
    setSaveBusy(false);
  }

  const handleStartAchDepositVerification = async () => {
    setSaveBusy(true);

    const request = {};
    const response = await client.postData("/user-portal/fpa/initiate-ach-deposit-verification", request);

    setVerificationMethod(VERIFICATION_METHOD_ACH_DEPOSIT);
    if (response.status === client.Error) {
      setVerificationStatus(VERIFICATION_STATUS_REJECTED);
    } else {
      setVerificationStatus(VERIFICATION_STATUS_PENDING_DEPOSIT);      
    }

    setSaveBusy(false);
  }

  const handleStartManualVerification = async () => {
    setSaveBusy(true);

    const request = {};
    const response = await client.postData("/user-portal/fpa/initiate-manual-verification", request);

    setVerificationMethod(VERIFICATION_METHOD_MANUAL);
    if (response.status === client.Error) {
      setVerificationStatus(VERIFICATION_STATUS_REJECTED);
    } else {
      setVerificationStatus(VERIFICATION_STATUS_PENDING_DEPOSIT);      
    }

    setSaveBusy(false);
  }

  const handleVerifyDepositAmounts = async () => {
    setSaveBusy(true);
    setAchVerificationErrorMessages([]);

    const request = {
      bank_account: {
        deposit_amount_1: achDeposit1,
        deposit_amount_2: achDeposit2
      }
    };
    const response = await client.postData("/user-portal/fpa/verify-ach-deposit-amounts", request);

    if (response.status === client.Error) {
      setAchVerificationErrorMessages(response.error_messages);
    } else {
      if (response.result?.bank_account.verification_status === VERIFICATION_STATUS_VERIFIED) {
        setVerificationStatus(VERIFICATION_STATUS_VERIFIED);
      } else {
        if (response.result?.bank_account.stripe_verification_attempts >= 3) {
          setVerificationStatus(VERIFICATION_STATUS_REJECTED);
        } else {
          setAchVerificationErrorMessages([`Incorrect verification values! Only ${3-response.result?.bank_account.stripe_verification_attempts} self-verification attempt(s) remaining. If you believe you are entering the correct values, please call us at: 800-275-5089 so we may assist you in verifying your account.`]);
        }
      }
    }

    setSaveBusy(false);
  }

  const save = () => {
    window.location.href = '/user-portal/fpa/review-and-submit'
  }

  const renderManualVerificationFailure = () => {
    return (
    <div className='row'>
      <div className='col-md-12 text-center'>
        <h1>Well, shucks!</h1>
        <h3>Bank verification could not be complete manually.</h3>
        <p>Please call us at: 800-275-5089 so we may further assist you in verifying your account.</p>
      </div>
    </div>
   );
  }

  const renderManualVerification = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center">
            <h1>We got this!</h1>
            <img src="/assets/rescue.gif" height="120px" />
            <p>
              We will manually review the information you have provided and attempt to verify your bank information with a $0.01 ACH deposit by one of our customer service staff <sup>[1]</sup>.
            </p>
            <p>
              If we need something, we'll followup by calling or emailing you. If everything looks good we'll auto approve your application. In the mean time, sit tight and enjoy the top-notch customer service.
            </p>
            <br/>
            <p>
              <sup>[1]</sup><em> Once verified, we will charge a one-time $20 fee for manual verification.</em>
            </p>
          </div>
        </div>
      </>
    )
  }



  const renderAchDepositVerificationSuccess = () => {
    return (
    <>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h1>Right On!</h1>
          <img src="/assets/piggybank.gif" height="120px" />
          <h3>Bank verification was successful</h3>
          <p>Now that your bank has been verified, you can continue and submit your application</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
            Next
            <div className="Ripple"></div>
          </button>
        </div>
      </div>
    </>
    );
  }

  const renderAchDepositVerificationFailure = () => {
    return (
    <div className='row'>
      <div className='col-md-12 text-center'>
        <h1>Oh no!</h1>
        <h3>Bank verification with ACH-Deposit could not be completed</h3>
        <p>We could not verify your account with the depoit amounts you provided</p>
        <p>Please call us at: 800-275-5089 so we may assist you in verifying your account.</p>
      </div>
    </div>
   );
 }

  const renderPlaidInstantVerificationSuccess = () => {
    return (
    <>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h1>Right On!</h1>
          <img src="/assets/piggybank.gif" height="120px" />
          <h3>Bank verification was successful</h3>
          <p>Now that your bank has been verified, you can continue and submit your application</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
            Next
            <div className="Ripple"></div>
          </button>
        </div>
      </div>
    </>
    );
  }

  const renderPlaidInstantVerificationFailure = () => {
    return (
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h1>Oh no!</h1>
          <h3>Bank verification could not be completed</h3>
          <p>The banking institution you chose did not have an account number that matches your Routing/Account numbers from the previous page.</p>
          <p>Go back to the <a href='/user-portal/fpa/bank'>'Bank'</a> page and verify your account numbers, then try your instant verifiation again.</p>
          <p>If you don't have access to your bank through Plaid, you can always use the ACH-Deposit verification process.</p>
        </div>
      </div>
    );
  }

  const renderVerificationChoices = () => {
    return (
    <>
      <p>
        We need to verify your bank account information. To do this, we have partnered with 3rd party bank verification providers. Choose from either instant verification or ACH-deposit verification using verifiable and small deposit amounts.
      </p>


      <div className="row mb-3">
        <div className="col-md-3">
          Bank Name: <strong>{bankName}</strong>
        </div>
        <div className="col-md-3">
          Account Type: <strong>{accountTypeDisplay(accountType)}</strong>
        </div>
        <div className="col-md-6">
          &nbsp;
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-4">
          <div className="card card-body">
            <h4 className="card-title">Instantly Verify with Plaid</h4>
            <p className="card-text">
              Is your bank a large, well known financial institution? If so, you might be able to verify your account by logging into your account and authorizing an identity verification.
            </p>
            <div className="flex-row">
              <button onClick={handleStartInstantVerification} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Start Instant Verification
                <div data-test="waves" className="Ripple"></div>
              </button>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="card card-body">
            <h4 className="card-title">Verify with Stripe ACH-Deposits</h4>
            <p className="card-text">
              If your bank isn't supported by Plaid, we can verify your bank information using automatic small ACH deposits, which you will then self-verify. This process takes 1-3 business days to complete.
            </p>
            <div className="flex-row">
              <button onClick={handleStartAchDepositVerification} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Start ACH-Deposit Verification
                <div data-test="waves" className="Ripple"></div>
              </button>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="card card-body">
            <h4 className="card-title">Verify Manually</h4>
            <p className="card-text">
              If you need some help verifying your bank, we can charge you a <strong><u>$20</u></strong> service fee to manually verify your banking information.
              This will entail us making an ACH penny deposit. This process may take up to 10 business days.
            </p>
            <div className="flex-row">
              <button onClick={handleStartManualVerification} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Start Manual Verification
                <div data-test="waves" className="Ripple"></div>
              </button>
            </div>
          </div>
        </div>

      </div>
    </>
    );
  }

  const renderStripeAchDepositVerification = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center">
            <h1>Cha-ching!</h1>
            <img src="/assets/deposit.gif" height="120px" />
            <p>
              We sent two small deposits to the bank account you listed. It may take 1-2 business days for the deposit to complete. If you know the deposit amounts, please enter them in the fields below (in no particular order).
            </p>
            <p>
              You can exit this application now and come back to continue after the deposits have been made.
            </p>

            <ErrorMessages errorMessages={achVerificationErrorMessages} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 offset-md-3">
            <div className="md-form">
              <input id='ach-deposit-1' value={achDeposit1} onChange={(e) => setAchDeposit1(e.target.value)} type="number" step="0.01" min="0.01" max="0.99" className="form-control" aria-disabled="false" placeholder="Ex: 0.17" />
              {renderSimpleLabel('Ach Deposit 1')}
            </div>
          </div>
          <div className="col-md-3">
            <div className="md-form">
              <input id='ach-deposit-2' value={achDeposit2} onChange={(e) => setAchDeposit2(e.target.value)} type="number" step="0.01" min="0.01" max="0.99"  className="form-control" aria-disabled="false" placeholder="Ex: 0.23" />
              {renderSimpleLabel('Ach Deposit 2')}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 text-center'>
            <button onClick={handleVerifyDepositAmounts} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
              Verify Deposit Amounts
              <div className="Ripple"></div>
            </button>
          </div>
        </div>
      </>
    )
  }

  const renderUIState = () => {
    if (verificationMethod === VERIFICATION_METHOD_PLAID_INSTANT) {
      if (verificationStatus === VERIFICATION_STATUS_VERIFIED) {
        return renderPlaidInstantVerificationSuccess();
      } else if (verificationStatus === VERIFICATION_STATUS_REJECTED ){
        return renderPlaidInstantVerificationFailure();
      } else {
        return renderVerificationChoices();
      }
    } else if (verificationMethod === VERIFICATION_METHOD_ACH_DEPOSIT) {
      if (verificationStatus === VERIFICATION_STATUS_VERIFIED) {
        return renderAchDepositVerificationSuccess();
      } else if (verificationStatus === VERIFICATION_STATUS_REJECTED) {
        return renderAchDepositVerificationFailure();
      } else if (verificationStatus === VERIFICATION_STATUS_PENDING_DEPOSIT) {
        return renderStripeAchDepositVerification();
      } else {
        return renderVerificationChoices();
      }
    } else if (verificationMethod === VERIFICATION_METHOD_MANUAL) {
      if (verificationStatus === VERIFICATION_STATUS_REJECTED) {
        return renderManualVerificationFailure();
      } else if (verificationStatus === VERIFICATION_STATUS_PENDING_DEPOSIT) {
        return renderManualVerification();
      }
    } else {
      return renderVerificationChoices();
    }
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Bank Verification</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />
          {renderUIState()}
        </>
      )}
      </div>
    </>
  </div>
  );
}



///

// NEEDS TO ADD 

// Try another method Option when in rejected state

///
///