import React from "react";

export default function StripeFieldErrors({ stripeErrors, fieldLabel, stripeFieldName}) {
  const messages = [];

  if (!stripeErrors) {
    return (<></>);
  }

  stripeErrors.forEach(error => {
    if (error.param === stripeFieldName) {
      if (error.code === 'parameter_invalid_empty') {
        messages.push({ text: `${fieldLabel} cannot be blank`, details: error.message });
      } else {
        messages.push({ text: `${fieldLabel} is invalid`, details: error.message });
      }
    }
  });

  return (
  <>
    { messages && messages.length > 0 && (
      <div className="stripe-field-errors">
        {messages.map((message, index) => (
          <div key={`stripe-field-error-${index}`} className="stripe-field-error">
            {message.text} <i className="fa fa-info-circle" title={message.details}></i>
          </div>
        ))}
    </div>
    )}
  </>
  );
}
