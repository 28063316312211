import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import { VERIFICATION_STATUS_PENDING_DEPOSIT } from "./BankVerification";
import UserSummaryCharts from "./shared/UserSummaryCharts";
import UserSummaryStats from "./shared/UserSummaryStats";

export default function Dashboard() {
  const [busy, setBusy] = useState(true);

  const [hasCompletedApplication, setHasCompletedApplication] = useState(false);
  const [applicationInProgress, setApplicationInProgress] = useState(false);
  const [needsSignature, setNeedsSignature] = useState(false);
  
  const [bankAccountVerificationStatus, setBankAccountVerificationStatus] = useState(false);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/dashboard");

    setHasCompletedApplication(response.result.has_completed_application);
    setApplicationInProgress(response.result.application_in_progress);
    setBankAccountVerificationStatus(response.result.bank_account?.verification_status);    
    setNeedsSignature(response.result.needs_signature);

    setBusy(false);
  }
  
  const handleApplicationClick = async () => {
    window.location = '/user-portal/fpa/personal';
  }

  const handleVerificationClick = async () => {
    window.location = '/user-portal/fpa/bank-verification';
  }

  const handleSignatureClick = async () => {
    window.location = '/user-portal/signature-requests';
  }

  const startOrResumeButton = () => {
    if (applicationInProgress) {
      if (bankAccountVerificationStatus === VERIFICATION_STATUS_PENDING_DEPOSIT) {
        return (
          <button onClick={handleVerificationClick} type="button" className="btn Ripple-parent btn-success">
            Verify Bank Account <div data-test="waves" className="Ripple"></div>
          </button>
        );
      } else {
        return (
          <button onClick={handleApplicationClick} type="button" className="btn Ripple-parent btn-success">
            Resume Application <div data-test="waves" className="Ripple"></div>
          </button>
        );
      }
    } else {
      return (
        <button onClick={handleApplicationClick} type="button" className="btn Ripple-parent btn-success">
          Start Application <div data-test="waves" className="Ripple"></div>
        </button>
      );
    }
  }

  const renderNothingToDo = () => {
    return (
    <>
      <div className='row'>
        <div className='col-sm-6 offset-md-3 text-center'>
          <h1>Welp, nothing to do.</h1>
          <img src="/assets/cats-bored.gif" height="120px" />
          <p>
            With no applications to fill out, or bank agreements to update, its pretty dull in here right now. Ho hum.
          </p>
          <div className='em-1'>
             Keep an eye out for email announcements and check back later!
          </div>
        </div>
      </div>
    </>
    );
  }

  return (
  <div className='container'>
    <>
{/*     
      <div className="mb-2 mt-5">
        <div className="row mb-4">
          <div className="col-sm-12">
            <UserSummaryCharts />
          </div>
        </div>
        <UserSummaryStats />
      </div>
 */}
      <h4 className="mb-2 mt-2">Dashboard</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <div className="row mb-4">
            { !hasCompletedApplication && (
              <div className="col-sm-4">
                <div className="card card-body">
                  <h4 className="card-title">{applicationInProgress ? 'Resume' : 'Start'} your application</h4>
                  <p className="card-text">
                    Apply to join the TSD Open Roads RV fuel discount program and enjoy discounts on diesel fuel throughout the continental United States of America.
                  </p>
                  <div className="flex-row">
                    {startOrResumeButton()}
                  </div>
                </div>
              </div>
            )}
            
            { needsSignature && (
              <div className="col-sm-4">
                <div className="card card-body">
                  <h4 className="card-title">Sign updated agreements</h4>
                  <p className="card-text">
                    You have outstanding banking agreements that need signatures in order for your account to remain in good standing
                  </p>
                  <div className="flex-row">
                    <button onClick={handleSignatureClick} type="button" className="btn Ripple-parent btn-success">
                      Review & Sign
                      <div data-test="waves" className="Ripple"></div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>          

          { hasCompletedApplication && !needsSignature && renderNothingToDo() }
        </>
      )}
      </div>
    </>
  </div>
  );
}
