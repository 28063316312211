import React from "react";

export default function ErrorMessages({ errorMessages, showHeading = false }) {
  return (
  <>
    { errorMessages && errorMessages.length > 0 && (
      <div className="validation-errors">
        {showHeading && <h2>These errors prevented you from saving...</h2>}
        {errorMessages.map((error_message, index) => (
          <div key={`validation-error-${index}`} className="validation-error">
            {error_message}
          </div>
        ))}
    </div>
    )}
  </>
  );
}
