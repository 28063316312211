import React, { useState, useEffect } from "react";

import ErrorMessages from "./ErrorMessages";
import LoadingIndicator from "./LoadingIndicator";
import client from "../client/Client";

export default function UserSummaryStats() {
  const [busy, setBusy] = useState(true);

  const [errorMessages, setErrorMessages] = useState([]);

  const [transactions, setTransactions] = useState();
  const [spending, setSpending] = useState();
  const [savings, setSavings] = useState();

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/ytd-stats");

    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    } else {
      setTransactions(response.result?.transactions);
      setSpending(response.result?.spending);
      setSavings(response.result?.savings);
    }
    
    setBusy(false);
  }

  return (
  <>
    {busy ? ( 
      <div className="row">
        <div className="col-md-12 text-center">
          <LoadingIndicator />
        </div>
      </div>
    ) : (
      <div className="row">

        { errorMessages && errorMessages.length > 0 ? (
          <ErrorMessages errorMessages={errorMessages} />
        ) : (
          <>
            <div className="col-xl-4 col-sm-12 col-12 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between px-md-1">
                    <div>
                      <h3 className="text-warning">{transactions}</h3>
                      <p className="mb-0">Transactions (Year to Date)</p>
                    </div>
                    <div className="align-self-center">
                      <i className="fas fa-gas-pump text-warning fa-3x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-12 col-12 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between px-md-1">
                    <div>
                      <h3 className="text-warning">${spending}</h3>
                      <p className="mb-0">Spending (Year to Date)</p>
                    </div>
                    <div className="align-self-center">
                      <i className="fas fa-money-check text-info fa-3x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-12 col-12 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between px-md-1">
                    <div>
                      <h3 className="text-warning">${savings}</h3>
                      <p className="mb-0">Savings (Year to Date)</p>
                    </div>
                    <div className="align-self-center">
                      <i className="fas fa-money-bill-wave text-success fa-3x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )}
  </>
  );
}
