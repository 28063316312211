import React, { useState } from "react";
import client from "./client/Client";
import { renderLabel } from "./shared/Utilities";
import ErrorMessages from "./shared/ErrorMessages";
import PasswordInput from "./shared/PasswordInput";
import ReCAPTCHA from 'react-google-recaptcha';

export default function Login(props) {
  const [busy, setBusy] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [errorMessages, setErrorMessages] = useState([]);

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleLoginClick = async () => {
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    // Handle login with the recaptcha token
    console.log("Submit login form with reCAPTCHA token:", recaptchaToken);

    const response = await client.postData("/user-portal/login", {
      username: username,
      password: password,
      recaptcha_token: recaptchaToken
    });
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setBusy(false)
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleLoginClick();
  }

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className='container'>
      <div className="row">
        <div className="col-md-4 offset-md-4 mt-5 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="text-center green-text">
                <img src="/assets/logo.png" height="120px"/>
                {/* <h3>
                  <i className="fas fa-lock green-text"></i> Login
                </h3> */}
                <hr className="my-4" />
              </div>

              <ErrorMessages errorMessages={errorMessages} />

              <form onSubmit={onFormSubmit}>
                <div className="md-form">
                  <i className="fas fa-envelope prefix grey-text"></i>
                  <input id='username' type='text' value={username} onChange={(e) => setUsername(e.target.value)} className="form-control"/>
                  {renderLabel('Your email', username, 'username')}
                </div>
                <div className="md-form">
                  <i className="fas fa-lock prefix grey-text"></i>
                  <PasswordInput value={password} onChange={(val) => setPassword(val)} id='password' />
                  {renderLabel('Your password', password, 'password')}
                </div>
                
                <ReCAPTCHA
                  sitekey={props.recaptcha_site_key}
                  onChange={onRecaptchaChange}
                />

                <div className="text-center">
                  <button type="submit" className="btn btn-success waves-effect waves-light" disabled={busy}>Login</button>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-center">
              <div className="options">
                <p className="text-muted">
                  Need to create an account?&nbsp;&nbsp;
                  <a href="/user-portal/registration">Sign Up</a>
                </p>
                <p className="text-muted">
                  Forgot your password?&nbsp;&nbsp;
                  <a href="/user-portal/forgot-password">Forgot Password</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
}
