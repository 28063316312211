import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";

import { renderLabel } from "./shared/Utilities";

export default function Referral() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [referredBySomeone, setReferredBySomeone] = useState(false);
  const [permanentlyReferredBy, setPermanentlyReferredBy] = useState(false);

  const [defaultBrandWelcomeMessage, setDefaultBrandWelcomeMessage] = useState('');

  const [referrerName, setReferrerName] = useState('');
  const [referrerAccountId, setReferrerAccountId] = useState();
  
  const [referrers, setReferrers] = useState();

  useEffect(() => {
    initialize();
  }, [])

  useEffect(() => {
    if (referrers) {
      const referrer = referrers.find((r) => r.id === referrerAccountId);
      setReferrerName(referrer?.brand_referral_code);
    }
  }, [referrerAccountId])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/referral");
    
    setReferrerName(response.result?.fuel_program_application.referred_by || '');
    setReferrerAccountId(response.result?.fuel_program_application.referrer_account_id);

    setReferredBySomeone(!!response.result?.fuel_program_application.referred_by);
    setPermanentlyReferredBy(response.result?.permanently_referred_by);

    setDefaultBrandWelcomeMessage(response.result?.default_brand_welcome_message);

    setReferrers(response.result?.referrer_accounts);

    setBusy(false);
  }

  const save = async () => {
    setSaveBusy(true);

    const request = {
      fuel_program_application: {
        referred_by: referrerName || '',
        referrer_account_id: referrerAccountId || ''
      }
    };

    const response = await client.postData("/user-portal/fpa/referral", request);
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  const handleReferredByChanged = (e) => {
    setReferrerName(e.target.value);
    setReferrerAccountId(null);
  }

  const handleReferredBySomeoneSelected = (selected) => {
    setReferredBySomeone(selected);

    if (!selected) {
      setReferrerName(null);
      setReferrerAccountId(null);
    }
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Referral Info</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />

          {permanentlyReferredBy ? (
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={permanentlyReferredBy.brand_logo_url} className="width-80" />
              </div>
              <div className="col-md-10">
                <div className="mb-3">
                  <strong>{permanentlyReferredBy.brand_name}</strong>
                </div>
                <div className="mb-3">
                  {permanentlyReferredBy.brand_welcome_message ? permanentlyReferredBy.brand_welcome_message : defaultBrandWelcomeMessage}
                </div>
                {permanentlyReferredBy.brand_welcome_video_embed_code && (
                  <div className="embedded-video" dangerouslySetInnerHTML={{ __html: permanentlyReferredBy.brand_welcome_video_embed_code }} />
                )}
              </div>
            </div>
          ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div>
                  Were you referred by someone?
                </div>
                <div className="ml-2">
                  <div>
                    <input name="referred_by_someone" type="radio" value={'Yes'} checked={referredBySomeone} onChange={(e) => handleReferredBySomeoneSelected(true)} />&nbsp;Yes
                  </div>
                  <div>
                    <input name="referred_by_someone" type="radio" value={'No'} checked={!referredBySomeone} onChange={(e) => handleReferredBySomeoneSelected(false)} />&nbsp;No
                  </div>
                </div>
              </div>
            </div>

            {referredBySomeone && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="md-form">
                    <input id='referrer-name' value={referrerName} onChange={handleReferredByChanged} type="text" className="form-control" aria-disabled="false" />
                    {renderLabel('Who were you refered by?', referrerName, 'referrer-name')}
                    <p>
                      <em>Enter a name of your referrer, or select from popular referrers on the right.</em>
                    </p>
                    <p>
                      <em>Help us identify the people responsible for growing our program, and recongnise their contribution.</em>
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <table cellPadding={5}>
                    <tbody>
                      {referrers.map((referrer, index) => (
                        <tr key={`referrer_${index}`}>
                          <td className="align-middle">
                              <input name="referrer_account_id" value={referrer.id} type="radio" checked={referrer.id === referrerAccountId} onChange={(e) => setReferrerAccountId(referrer.id)} />
                          </td>
                          <td className="align-middle">
                            <img src={referrer.brand_logo_url} className='tiny-brand-image' />&nbsp;
                          </td>
                          <td className="align-middle">
                            {referrer.brand_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            )}
          </> 
          )}

          <div className="row mt-5 mt-5">
            <div className="col-md-12">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
