import React from "react";

export default function CheckboxInput({checked, onChange, id, label}) {
  
  const handleChanged = (password) => {
    onChange(password);
  }

  return (
    <div class="custom-control custom-checkbox">
      <input id={id} type={'checkbox'} checked={checked} onChange={(e) => handleChanged(e.target.checked)} class="custom-control-input" />
      <label class="custom-control-label font-weight-200" for={id}>{label}</label>
    </div>
  );
}
