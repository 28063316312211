import React, { useEffect } from "react";

export default function LineChart({data, id}) {

  // Data example:
  // {
  //   labels: ["JAXY", "FEGAY", "March", "April", "May", "June", "July"],
  //   datasets: [{
  //      label: "My First dataset",
  //      data: [65, 59, 80, 81, 56, 55, 40],
  //      backgroundColor: [
  //      'rgba(105, 0, 132, .2)',
  //      ],
  //      borderColor: [
  //      'rgba(200, 99, 132, .7)',
  //      ],
  //      borderWidth: 2
  //   }]
  // };

  useEffect(() => {
    initChart(data);
  }, [])

  const initChart = (data) => {
    var ctxB = document.getElementById(id).getContext('2d');
    var myChart = new Chart(ctxB, {
      type: 'line',
      data: data,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  return (
  <>
    <canvas id={id}></canvas>
  </>
  );
}
