import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ErrorMessages from "./shared/ErrorMessages";
import { GoogleMap, LoadScript, Marker, DirectionsRenderer, DirectionsService, InfoWindow} from '@react-google-maps/api';
import { renderLabel } from "./shared/Utilities";

// export function InfoWindowEx(props) {
//   const infoWindowRef = React.createRef();
//   const contentElement = document.createElement(`div`);
//   useEffect(() => {
//     ReactDOM.render(React.Children.only(props.children), contentElement);
//     infoWindowRef.current.setContent(contentElement);
//   }, [props.children, infoWindowRef]);
//   return <InfoWindow ref={infoWindowRef} {...props} />;
// }

export default function TripPlanning() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [googleMapsApiKey, setGoogleMapsApiKey] = useState();

  const [errorMessages, setErrorMessages] = useState([]);

  const [origin, setOrigin] = useState('Harper, KS');
  const [destination, setDestination] = useState('Destin, FL');
  const [waypoints, setWaypoints] = useState([]);
  
  const [selectedMarker, setSelectedMarker] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  
  const [directionsRequest, setDirectionsRequest] = useState();
  const [directionsGoogleApiResponse, setDirectionsGoogleApiResponse] = useState();
  const [directionsFuelLocationsApiResponse, setDirectionsFuelLocationsApiResponse] = useState();

  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    setBusy(true);

    const response = await client.getData("/user-portal/trip-planning");

    setGoogleMapsApiKey(response.result?.google_maps_api_key);

    setBusy(false);
  }

  const getDirections = async () => {
    setSaveBusy(true);

    const request = {
      origin: origin,
      destination: destination,
      waypoints: waypoints.map((wypt) => {
        return { location: wypt, stopover: true }
      }),
      travelMode: 'DRIVING'
    };

    setDirectionsGoogleApiResponse(undefined);
    setDirectionsFuelLocationsApiResponse(undefined);
    setDirectionsRequest(request);
    
    setSaveBusy(false);
  }

  const addWaypoint = () => {
    const waypts = [...waypoints];
    waypts.push("");
    setWaypoints(waypts); 
  }

  const removeWaypoint = (indx) => {
    const waypts = [...waypoints];
    waypts.splice(indx, 1);
    setWaypoints(waypts);   
  }

  const updateWaypoint = (value, indx) => {
    const waypts = [...waypoints];
    waypts[indx] = value;
    setWaypoints(waypts);
  }

  const onDirectionsReceived = async (googleApiResponse) => {
    setDirectionsGoogleApiResponse(googleApiResponse);

    const response = await client.postData("/user-portal/trip-planning", { directions_api_response: googleApiResponse });
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setDirectionsFuelLocationsApiResponse(response);
  }

  const setSelected = (location, marker) => {
    setSelectedLocation(location);
    setSelectedMarker(marker);
	}
  
  return (
  <div className='container'>
    <>
      <h4 className="mb-2 mt-2">Trip Planning</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />

          <div className="row mb-3">
            <div className="col-md-12">
              <div>
                Build and plan a trip using fuel location and pricing from the Open Roads app.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <div className="md-form">
                <input id='starting-city-name' value={origin} onChange={(e) => setOrigin(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Origin City', origin, 'starting-city-name')}
              </div>
            </div>
            <div className="col-md-5">
              <div className="md-form">
              <input id='ending-city-name' value={destination} onChange={(e) => setDestination(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Destination City', destination, 'ending-city-name')}
              </div>
            </div>
            <div className="col-md-2">
              <button onClick={addWaypoint} type="button" className="btn Ripple-parent btn-default" disabled={saveBusy}>
                Add Stop
                <div className="Ripple"></div>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="md-form">
                {waypoints.map((waypoint, indx) => (
                  <div className="row">
                    <div className="col-md-10">
                      <div className="md-form">
                        <input id={`waypoint${indx}`} value={waypoint} onChange={(e) => updateWaypoint(e.target.value, indx)} type="text" className="form-control" aria-disabled="false" />
                        {renderLabel('Waypoint City/State', waypoint, `waypoint${indx}`)}
                      </div>
                    </div>
                    <div className="col-md-2 text-right">
                      <button onClick={() => removeWaypoint(indx)} type="button" className="btn Ripple-parent btn-danger" disabled={saveBusy}>
                        Remove
                        <div className="Ripple"></div>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-left">
              <button onClick={getDirections} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Get Directions & Fuel Prices
                <div className="Ripple"></div>
              </button>
            </div>
          </div>

          <hr />

          <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap mapContainerStyle={containerStyle} zoom={10}>
              {/*
                Renders the fuel price locations from the hydrocarbons API call
              */}
              {directionsFuelLocationsApiResponse ? (
              <>
                {directionsFuelLocationsApiResponse.result?.locations.map((location) => (
                  <Marker 
                    position={{lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}}
                    onClick={(marker) => setSelected(location, marker)} 
                  />
                ))}
              </>
              ) : (<></>)}

              { selectedMarker && selectedLocation && (
              <InfoWindow
                position={selectedMarker.latLng}
                onCloseClick={() => setSelected(undefined, undefined)}>
                <div>
                  <div>
                    {selectedLocation.store_name}
                  </div>
                  <div>
                    ${selectedLocation.discounted_price}/gal
                  </div>
                </div>
              </InfoWindow>
              )}

              {/*
                Renders the polylines on the map when the google directions response returns
              */}
              {directionsGoogleApiResponse && (
                <DirectionsRenderer
                  directions={directionsGoogleApiResponse}
                  onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                  }}
                  onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                  }}
                />
              )}

              {/*
                Updates directions when directionsRequest is available and when the google directions response has 
                been cleared or never set before 
              */}
              {directionsRequest && !directionsGoogleApiResponse && (
                <DirectionsService
                  options={directionsRequest}
                  callback={onDirectionsReceived}
                  onLoad={directionsService => {
                    console.log('DirectionsService onLoad directionsService: ', directionsService)
                  }}
                  onUnmount={directionsService => {
                    console.log('DirectionsService onUnmount directionsService: ', directionsService)
                  }}
                />
              )}
            </GoogleMap>
          </LoadScript>
        </>
      )}
      </div>
    </>
  </div>
  );
}
