import React, { useState } from "react";

export default function PasswordInput({password, onChange, id}) {
  const [showPassword, setShowPassword] = useState(false);
  
  const handleSetPassword = (password) => {
    onChange(password);
  }

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
  <>
    <input id={id} type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => handleSetPassword(e.target.value)} className="form-control" />
    <span onClick={handleToggleShowPassword} toggle="#password-field" class={`fa fa-fw ${showPassword ? 'fa-eye' : 'fa-eye-slash'} field-icon toggle-password`}></span>
  </>
  );
}
