import React, { useState } from "react";
import client from "./client/Client";
import ErrorMessages from "./shared/ErrorMessages";
import PasswordInput from "./shared/PasswordInput";

import { renderLabel } from "./shared/Utilities";

const MODE_SEND_CODE = 'Send Code';
const MODE_ENTER_CODE = 'Enter Code';
const MODE_CHANGE_PASSWORD = 'Change Password';

export default function ForgotPassword() {
  const [busy, setBusy] = useState(false);

  const [mode, setMode] = useState(MODE_SEND_CODE);

  const [email, setEmail] = useState('');
  
  const [verificationCode, setVerificationCode] = useState();
  const [passwordChangeToken, setPasswordChangeToken] = useState();

  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();

  const [errorMessages, setErrorMessages] = useState([]);

  const handleGeneratePasswordResetCodeClick = async () => {
    setBusy(true);
    setErrorMessages([]);

    const response = await client.postData("/user-portal/generate-password-reset-code", {
      email: email
    });

    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    } else {
      setMode(MODE_ENTER_CODE);
    }

    setBusy(false);
  }

  const handleVerifyPasswordResetCode = async () => {
    setBusy(true);
    setErrorMessages([]);

    const response = await client.postData("/user-portal/verify-password-reset-code", {
      email: email,
      password_reset_code: verificationCode
    });
    
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    } else {
      setPasswordChangeToken(response.result?.password_change_token);
      setMode(MODE_CHANGE_PASSWORD);
    }

    setBusy(false);
  }

  const handleChangePassword = async () => {
    setBusy(true);
    setErrorMessages([]);

    const response = await client.postData("/user-portal/change-password", {
      email: email,
      password_change_token: passwordChangeToken,
      password: newPassword,
      password_confirmation: newPasswordConfirm
    });
    
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setBusy(false);
  }

  const onFormSubmitSendCode = (e) => {
    e.preventDefault();
    handleGeneratePasswordResetCodeClick();
  }

  const onFormSubmitEnterCode = (e) => {
    e.preventDefault();
    handleVerifyPasswordResetCode();
  }

  const onFormSubmitChangePassword = () => {
    e.preventDefault();
    handleChangePassword();
  }

  return (
    <div className='container'>
      <div className="row">
        <div className="col-md-4 offset-md-4 mt-5 mb-4">

          <ErrorMessages errorMessages={errorMessages} />

          { mode === MODE_SEND_CODE && (
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img src="/assets/logo.png" height="120px"/>
                  <p className="text-muted">
                    Enter your email and we will send a password reset code if the email corresponds to an existing account.
                  </p>
                </div>

                <form onSubmit={onFormSubmitSendCode}>
                  <div className="md-form mt-4 mb-4">
                    <i className="fas fa-envelope prefix grey-text"></i>
                    <input id='email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} className="form-control"/>
                    {renderLabel('Your email', email, 'email')}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-success waves-effect waves-light" onClick={handleGeneratePasswordResetCodeClick} disabled={busy}>
                      Send Reset Code
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="modal-footer justify-content-center">
                <div className="options">
                  <p className="text-muted">
                    Remember your password?&nbsp;&nbsp;
                    <a href="/user-portal/login">Login</a>
                  </p>
                </div>
              </div> */}
            </div>
          )}

          { mode === MODE_ENTER_CODE && (
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img src="/assets/logo.png" height="120px"/>
                  <p className="text-muted">
                    Enter the verification code we sent you.
                  </p>
                </div>

                <form onSubmit={onFormSubmitEnterCode}>
                  <div className="md-form mt-4 mb-4">
                    <i className="fas fa-lock prefix grey-text"></i>
                    <input id='verification-code' type='text' value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control"/>
                    {renderLabel('Verification Code', verificationCode, 'verification-code')}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-success waves-effect waves-light" onClick={handleVerifyPasswordResetCode} disabled={busy}>Verify Reset Code</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          { mode === MODE_CHANGE_PASSWORD && (
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img src="/assets/logo.png" height="120px"/>
                  <p className="text-muted">
                    Enter a new password and password confirmation
                  </p>
                </div>

                <form onSubmit={onFormSubmitChangePassword}>
                  <div className="md-form mt-4">
                    <i className="fas fa-lock prefix grey-text"></i>
                    <PasswordInput value={newPassword} onChange={(val) => setNewPassword(val)} id='new-password' />
                    {renderLabel('New Password', newPassword, 'new-password')}
                  </div>
                  <div className="md-form mb-4">
                    <i className="fas fa-lock prefix grey-text"></i>
                    <PasswordInput value={newPasswordConfirm} onChange={(val) => setNewPasswordConfirm(val)} id='new-password-confirm' />
                    {renderLabel('Confirm Password', newPasswordConfirm, 'new-password-confirm')}
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-success waves-effect waves-light" onClick={handleChangePassword} disabled={busy}>Change Password</button>
                  </div>
                </form>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}
