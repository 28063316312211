export class Client {
    Success = 'Success';
    Error = 'Error';

    async getData(path) {
        let fullPath = path;
        const result = await fetch(fullPath, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
        });

        const resultJson = await result.json();
        if (resultJson.result?.redirect_url) {
            window.location = resultJson.result?.redirect_url;
        } else {
            return resultJson;
        }
    }

    async postData(path, params) {
        const token = document.querySelector('meta[name="csrf-token"]').content;

        let fullPath = path;
        const result = await fetch(fullPath, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "X-CSRF-Token": token
            },
            body: JSON.stringify(params)
        });

        const resultJson = await result.json();
        if (resultJson.result?.redirect_url) {
            window.location = resultJson.result?.redirect_url;
        } else {
            return resultJson;
        }
    }
}

const client = new Client();
export default client;