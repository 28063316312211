import React, { useState } from "react";

export const accountTypeDisplay = (accountType) => {
    return accountType === 'C' ? 'Checking' : 'Savings';
}

export const renderLabel = (title, value, label_for = null) => {
    return (
        <label className={value && 'active'} for={label_for}>
            {title}
        </label>
    )
}

export const renderSimpleLabel = (title) => {
    return (
        <label className='active'>
            {title}
        </label>
    )
}

export const renderCheckboxLabel = (label) => {
    return (
        <label className='active in-line'>
            {label}
        </label>
    )
}