import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { accountTypes } from "./shared/AccountTypes";

const FASTEST = 'FASTEST';
const FAST = 'FAST';
const SLOW = 'SLOW';

export default function OrderOptions() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [deliveryOption, setDeliveryOption] = useState(SLOW);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/order-options");
    
    if (response.result?.fuel_program_application.expedited_shipping === 'Y') {
      setDeliveryOption(FASTEST);
    } else if (response.result?.fuel_program_application.expedited_processing === 'Y') {
      setDeliveryOption(FAST);
    } else {
      setDeliveryOption(SLOW);
    }

    setBusy(false);
  }

  const save = async () => {
    setSaveBusy(true);

    const request = {
      fuel_program_application: {
        expedited_shipping: deliveryOption === FASTEST ? 'Y' : 'N',
        expedited_processing: (deliveryOption === FAST || deliveryOption === FASTEST) ? 'Y' : 'N'
      }
    };

    const response = await client.postData("/user-portal/fpa/order-options", request);
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Order Info</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />

          <div className="row mb-3">
            <div className="col-md-12">
              <div>
                Do you want to expedite the delivery of your fuel card?
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ml-2">
                <div>
                  <input name="expedited_processing" type="radio" checked={deliveryOption === FASTEST} onChange={(e) => setDeliveryOption(FASTEST)} />
                  &nbsp;Yes - Fastest option
                  <ul>
                    <li>48-hour processing</li>
                    <li>Overnight shipping</li>
                    <div>
                      US - A one-time charge of $75 USD<br/>
                      Canada - A one-time charge of $120 USD
                    </div>
                  </ul>
                </div>
                <div>
                  <input name="expedited_processing" type="radio" checked={deliveryOption === FAST} onChange={(e) => setDeliveryOption(FAST)} />
                  &nbsp;Yes
                  <ul>
                    <li>48-hour processing</li>
                    <div>
                      A one-time charge charge of $20 USD
                    </div>
                  </ul>
                </div>
                <div>
                  <input name="expedited_processing" type="radio" checked={deliveryOption === SLOW} onChange={(e) => setDeliveryOption(SLOW)} />
                  &nbsp;No
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
