import React, { useEffect } from "react";

export default function BarChart({data, id}) {

  // Data example:
  // {
  //   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  //   datasets: [{
  //     label: '# of Votes',
  //     data: [12, 19, 3, 5, 2, 3],
  //     backgroundColor: [
  //       'rgba(255, 99, 132, 0.2)',
  //       'rgba(54, 162, 235, 0.2)',
  //       'rgba(255, 206, 86, 0.2)',
  //       'rgba(75, 192, 192, 0.2)',
  //       'rgba(153, 102, 255, 0.2)',
  //       'rgba(255, 159, 64, 0.2)'
  //     ],
  //     borderColor: [
  //       'rgba(255,99,132,1)',
  //       'rgba(54, 162, 235, 1)',
  //       'rgba(255, 206, 86, 1)',
  //       'rgba(75, 192, 192, 1)',
  //       'rgba(153, 102, 255, 1)',
  //       'rgba(255, 159, 64, 1)'
  //     ],
  //     borderWidth: 1
  //   }]
  // }

  useEffect(() => {
    initChart(data);
  }, [])

  const initChart = (data) => {
    var ctxL = document.getElementById(id).getContext('2d');
    var myChart = new Chart(ctxL, {
       type: 'bar',
       data: data,
       options: {
          responsive: true
       }
    });
  }

  return (
  <>
    <canvas id={id}></canvas>
  </>
  );
}
