import React, { useState, useEffect } from "react";
import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";
import PieChart from "./charts/PieChart";

import LoadingIndicator from "./LoadingIndicator";
import client from "../client/Client";

export default function UserSummaryCharts() {
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/dashboard");

    setBusy(false);
  }

  const lineChartData = {
    labels: ["JAXY", "FEGAY", "March", "April", "May", "June", "July"],
    datasets: [{
       label: "My First dataset",
       data: [65, 59, 80, 81, 56, 55, 40],
       backgroundColor: [
       'rgba(105, 0, 132, .2)',
       ],
       borderColor: [
       'rgba(200, 99, 132, .7)',
       ],
       borderWidth: 2
    }]
  };

  const barChartData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  };

  const pieChartData = {
    labels: ["Red", "Green", "Yellow", "Grey", "Dark Grey"],
    datasets: [{
      data: [300, 50, 100, 40, 120],
      backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"],
      hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5", "#616774"]
    }]
  };

  return (
  <>
    <h4 className="mb-2 mt-2">Account History</h4>
    <div className="container-fluid section mb-5 border p-4">
      {busy ? ( 
        <div className="row">
          <div className="col-md-12 text-center">
            <LoadingIndicator />
          </div>
        </div>
      ) : (
      <div className="row">
        <div className="col-md-4">
          <LineChart data={lineChartData} id={'butter-graph'} />
        </div>
        <div className="col-md-4">
          <BarChart data={barChartData} id={'bread-graph'} />
        </div>
        <div className="col-md-4">
          <PieChart data={pieChartData} id={'jelly-graph'} />
        </div>
      </div>
      )}
    </div>
  </>
  );
}
