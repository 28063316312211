import React, { useEffect, useState } from "react";
import LoadingIndicator from "./LoadingIndicator";
import client from "../client/Client";

export default function ApplicationProgress() {
  const [busy, setBusy] = useState(true);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/progress");
    
    if (response.result?.is_partner) {
      setLocations([
        { title: 'Personal', path: 'personal' },
        { title: 'Order', path: 'order-options' },
        { title: 'Bank', path: 'bank' },
        { title: 'Signature', path: 'signature' },
        { title: 'Verification', path: 'bank-verification' },
        { title: 'Review & Submit', path: 'review-and-submit' },
        { title: 'Complete', path: 'thank-you' },
      ]);
    } else {
      setLocations([
        { title: 'Personal', path: 'personal' },
        { title: 'Referral', path: 'referral' },
        { title: 'Order', path: 'order-options' },
        { title: 'Bank', path: 'bank' },
        { title: 'Signature', path: 'signature' },
        { title: 'Verification', path: 'bank-verification' },
        { title: 'Review & Submit', path: 'review-and-submit' },
        { title: 'Complete', path: 'thank-you' },
      ]);
    }

    setBusy(false);
  }

  const renderCrumb = (location, index) => {
    const active = window.location.href.endsWith(location.path);
    const myIndex = locations.findIndex((location) => window.location.href.endsWith(location.path));
    return (
        <li key={`step_${index}`} className={`breadcrumb-item ${active && 'active'}`}>
          {index > myIndex ? location.title : (
            <>
              {(active) ? (<strong><u>{location.title}</u></strong>) : (<a href={location.path}>{location.title}</a>) }
            </>
          )}
        </li>
    );
  }

  if (busy) {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <ol className="breadcrumb mt-2">
        <strong>Application Progress:&nbsp;&nbsp;</strong>
        { locations.map((location, index) => renderCrumb(location, index)) }
    </ol>
  );
}
