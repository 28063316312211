import React, { useEffect } from "react";

export default function PieChart({data, id}) {

  // Data example:
  // {
  //   labels: ["Red", "Green", "Yellow", "Grey", "Dark Grey"],
  //   datasets: [{
  //     data: [300, 50, 100, 40, 120],
  //     backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"],
  //     hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5", "#616774"]
  //   }]
  // }

  useEffect(() => {
    initChart(data);
  }, [])

  const initChart = (data) => {
    var ctxP = document.getElementById(id).getContext('2d');
    var myChart = new Chart(ctxP, {
      type: 'pie',
      data: data,
      options: {
        responsive: true
      }
    });
  }

  return (
  <>
    <canvas id={id}></canvas>
  </>
  );
}
