import React from "react";

export default function StripeErrors({ stripeErrors }) {
  const messages = [];

  if (!stripeErrors) {
    return (<></>);
  }

  stripeErrors.forEach(error => {
    if (error.param !== 'bank_account[routing_number]' && error.param !== 'bank_account[account_number]') {
      messages.push(error.message);
    }
  });

  return (
  <>
    { messages && messages.length > 0 && (
      <div className="stripe-errors">
        {messages.map((message, index) => (
          <div key={`stripe-error-${index}`} className="stripe-error">
            {message}
          </div>
        ))}
    </div>
    )}
  </>
  );
}
